import React, { useState, useEffect } from 'react';
import Navbar from '../Outlets/Navbar';
import Footer from '../Outlets/Footer';
import { Outlet } from 'react-router-dom';
import logo from '../Assets/LOGO.png'
import w from '../Assets/social.png'

const Layout = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Show splash screen for 1 second on page refresh
    const timer = setTimeout(() => {
      setLoading(false);
    }, 900);

    // Clean up the timer when component unmounts
    return () => clearTimeout(timer);
  }, []);

  // Splash screen component within Layout
  const SplashScreen = () => {
    return (
      <div style={styles.splashScreen}>
        <img src={logo} alt="Logo" style={styles.logo} />
      </div>
    );
  };

  const styles = {
    splashScreen: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: '#fff',  // Splash screen background color
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
    },
    logo: {
      width: '300px',  // Adjust logo size
    },
  };


  const message = encodeURIComponent("Hello! I would like more information about your Services.");
	const phone = "+96878909514";
	const whatsappUrl = `https://api.whatsapp.com/send?phone=${phone}&text=${message}`;

	

  return (
    <div>
      {loading ? (
        <SplashScreen />  // Show the splash screen while loading is true
      ) : (
        <>
          <Navbar />
          <a
            href={whatsappUrl}
            className="float"
            target="_blank"
          >
            <img src={w} className="wicon" alt="WhatsApp Icon" />
          </a>


          <Outlet />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Layout;
