import React from 'react'
import './Outlet.css';
import logofooter from '../Assets/Latest/white (1).png'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footer-main'>
      <div className="container-main">
        <div className="row">
          <div className="col-lg-3">
            <div className="footer-inner">
              <img src={logofooter} className='footer-logo' alt="" />
              <p className='footer-sub-text'>Step into the world of modern design and functionality with Techno Interiors, the most trusted brand in uPVC Windows and Doors.</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-inner">
              <h4 className='title-footer'>Quick Links</h4>
              <ul>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/about'>About Us</Link></li>
                <li><Link to='/products'>Products</Link></li>
                <li><Link to='/services'>Services</Link></li>
                <li><Link to='/contact'>Contact Us</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-inner">
              <h4 className='title-footer'>Find Us On</h4>
              <div className="social-media">
                <a href="https://www.instagram.com/technofacade11/" target="_blank" rel="noopener noreferrer">
                  <i class="ri-instagram-line"></i>
                </a>

                <i class="ri-facebook-circle-line"></i>
                <i class="ri-google-fill"></i>
                <i class="ri-twitter-x-line"></i>
                <i class="ri-vimeo-fill"></i>
                <div className="details">
                  <p className='icon-footer'>
                    <a href="tel:+96878909514" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <i className="ri-phone-fill"></i> +96878909514
                    </a>
                  </p>                  <p className='icon-footer'>
                    <a href="mailto:info@technofacade.com" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <i className="ri-mail-unread-line"></i> info@technofacade.com
                    </a>
                  </p>                  <p className='icon-footer'><i class="ri-map-pin-fill"></i> TECHNO FACADE INTERIOR AND EXTERIOR
                    BIDIYATH AL MAJAD GLOBAL PROJECT
                    CR 1372024
                    BARKA,SULTANATE OF OMAN</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-inner">
              <h4 className='title-footer'>Locate Us</h4>
              <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3655.6251461644524!2d57.862334!3d23.617773000000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjPCsDM3JzA0LjAiTiA1N8KwNTEnNDQuNCJF!5e0!3m2!1sen!2sin!4v1730114952838!5m2!1sen!2sin" width="100" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="footer-copyright">
              <p>© 2019 All Right Reserved By TECHNO FACADE</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer