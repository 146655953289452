import React, { useRef, useEffect } from 'react';
import mission from '../Assets/aboutt.svg'
import vission from '../Assets/img.svg'
import about from '../Assets/Latest/int.png'
import AOS from 'aos';
import { Link } from 'react-router-dom';
import ai from '../Assets/new/ai.svg'
import 'aos/dist/aos.css';
const About = () => {

	useEffect(() => {
		AOS.init({
			duration: 1000, // Animation duration in milliseconds
			once: true, // Trigger animation only once
		});

		window.addEventListener('scroll', AOS.refresh); // Refresh AOS on scroll

		return () => {
			window.removeEventListener('scroll', AOS.refresh); // Cleanup on component unmount
		};
	}, []);



	return (
		<div className='about-page'>
			<div className="common-banner b">
				<div className="container-main">
					<h1 className='banner-title'>About Us</h1>
					<p className='sub-title-banner'>Discover our journey and commitment to delivering excellence</p>
				</div>
			</div>
			<div className="aboutus-section top-spacex">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-6 col-md-6">
							<div className="about-inner-box btmm" data-aos="fade-right">
								<img src={about} className='about-image' alt="" />
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="about-inner-box" data-aos="fade-left">
								<h4 className='title-about-us'>About Us</h4>
								<h2 className='title-about-us-main'>Who We Are</h2>
								<p className='sub-t-about-us'>Step into the world of modern design and functionality with Techno Interiors,
									the most trusted brand in uPVC Windows and Doors. As an Oman-based
									manufacturer, we pride ourselves on delivering superior quality products that
									enhance the aesthetics and performance of any space. Our mission is to
									provide innovative solutions that elevate our clients' lifestyles. Our vision is to
									be the industry leader in sustainable and energy-efficient windows and doors.
									With our EnCraft uPVC windows and doors, you can enjoy the perfect blend
									of style, safety, and security. Choose Techno Interiors for the best uPVC
									windows and doors that transform any space into a haven of comfort.
								</p>
								<div className="border-dv"></div>
								<Link to='/' className='btn-home-explore'>More about us</Link>
								<Link to='/' className='btn-home-download-a'>Download Brochure</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="exclusive">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-12">
							<div className="exclusive-box">
								<div className="exclusv-half">
									<h2 className='exclusive-text'>Exclusive</h2>
									<p className='exclusive-sub-text'>Get exclusive access to special deals, early product launches, and rewards every time you
										shop with us. Join our loyalty program today and start saving on your favorite products!</p>
								</div>
								<div className="exclusv-icon">
									<img src={ai} className='img-ai' alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	)
}

export default About