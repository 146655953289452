import React, { useRef, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";
import offer from '../Assets/Latest/wp.png'
import ai from '../Assets/new/ai.svg'
import { Link } from 'react-router-dom';
import offer2 from '../Assets/Latest/shutter.svg'
import offer3 from '../Assets/Latest/products/kitchen2.png'
import offer33 from '../Assets/Latest/products/crtwall.png'
import offer4 from '../Assets/Latest/products/tmbglass.png'
import offer5 from '../Assets/Latest/products/upvcdoor.png'
import offer6 from '../Assets/Latest/products/almnwindow.png'
import AOS from 'aos';
import 'aos/dist/aos.css';


const Products = () => {


	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnHover: false,
		arrows: false,
		responsive: [

			{
				breakpoint: 768, // At screen width 768px and below
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480, // At screen width 480px and below
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	};
	useEffect(() => {
		AOS.init({
			duration: 1000, // Animation duration in milliseconds
			once: true, // Trigger animation only once
		});

		window.addEventListener('scroll', AOS.refresh); // Refresh AOS on scroll

		return () => {
			window.removeEventListener('scroll', AOS.refresh); // Cleanup on component unmount
		};
	}, []);

	return (
		<div className='about-page'>
			<div className="common-banner c">
				<div className="container-main">
					<h1 className='banner-title'>Products</h1>
					<p className='sub-title-banner'>Discover Quality and Innovation in Every Product</p>
				</div>
			</div>
			<div className="products-page-div" data-aos="fade-down"
				data-aos-easing="linear"
				data-aos-duration="1500">
				<div className="container-main">
					<div className="row">
					<div className="col-lg-4">
							<div className="products-box-main">
								<div className="offer-slider">
									<img src={offer5} className='offer-img-sldr' alt="" />
									<div className="items-boxx">
										<h3 className='item-namee'>		 UPVC DOORS WINDOWS								</h3>
										<p className='item-subb'>a hard plastic material commonly
											used in windows and doors</p>
										<Link to='/contact' className='btn-home-shop'>Shop Now</Link>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="products-box-main">
								<div className="offer-slider">
									<img src={offer6} className='offer-img-sldr' alt="" />
									<div className="items-boxx">
										<h3 className='item-namee'>ALUMINIUM & WINDOWS								</h3>
										<p className='item-subb'>Aluminium windows have a very slim profile
											that defines the light system,</p>
										<Link to='/contact' className='btn-home-shop'>Shop Now</Link>
									</div>
								</div>
							</div>
						</div>






						<div className="col-lg-4">
							<div className="products-box-main">
								<div className="offer-slider">
									<img src={offer} className='offer-img-sldr' alt="" />
									<div className="items-boxx">
										<h3 className='item-namee'> 	WPC DOORS	</h3>
										<p className='item-subb'>is a hard plastic material commonly
											used in windows and doors. </p>
										<Link to='/contact' className='btn-home-shop'>Shop Now</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="products-box-main">
								<div className="offer-slider">
									<img src={offer4} className='offer-img-sldr' alt="" />
									<div className="items-boxx">
										<h3 className='item-namee'>TEMPERED GLASS									</h3>
										<p className='item-subb'>Tempered glass is known for its strength and
											safety</p>
										<Link to='/contact' className='btn-home-shop'>Shop Now</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="products-box-main">
								<div className="offer-slider">
									<img src={offer3} className='offer-img-sldr' alt="" />
									<div className="items-boxx">
										<h3 className='item-namee'>KITCHEN INTERIOR											</h3>
										<p className='item-subb'>The kitchen, being one of the areas in the
											house that has heavy footfall, needs to
											remain very well organized</p>
										<Link to='/contact' className='btn-home-shop'>Shop Now</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="products-box-main">
								<div className="offer-slider">
									<img src={offer33} className='offer-img-sldr' alt="" />
									<div className="items-boxx">
										<h3 className='item-namee'>ALIMINIUM CURTAIN WALLS			</h3>
										<p className='item-subb'>A curtain wall is any exterior wall that is attached
											to the building structure</p>
										<Link to='/contact' className='btn-home-shop'>Shop Now</Link>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="products-box-main">
								<div className="offer-slider">
									<img src={offer2} className='offer-img-sldr' alt="" />
									<div className="items-boxx">
										<h3 className='item-namee'>SHUTTERS & GATES											</h3>
										<p className='item-subb'>Shutters and gates play an integral role in securing
											homes and places</p>
										<Link to='/contact' className='btn-home-shop'>Shop Now</Link>
									</div>
								</div>
							</div>
						</div>
						
						
						
					</div>
				</div>
			</div>
			<div className="exclusive">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-12">
							<div className="exclusive-box">
								<div className="exclusv-half">
									<h2 className='exclusive-text'>Exclusive</h2>
									<p className='exclusive-sub-text'>Get exclusive access to special deals, early product launches, and rewards every time you
										shop with us. Join our loyalty program today and start saving on your favorite products!</p>
								</div>
								<div className="exclusv-icon">
									<img src={ai} className='img-ai' alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Products