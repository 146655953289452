import React, { useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import call from '../Assets/new/call.png'
import location from '../Assets/new/pin (1).png'
import mail from '../Assets/new/email.png'

const Contact = () => {

	useEffect(() => {
		AOS.init({
			duration: 1000, // Animation duration in milliseconds
			once: true, // Trigger animation only once
		});

		window.addEventListener('scroll', AOS.refresh); // Refresh AOS on scroll

		return () => {
			window.removeEventListener('scroll', AOS.refresh); // Cleanup on component unmount
		};
	}, []);

	return (
		<div className='contact-page'>
			<div className="common-banner d">
				<div className="container-main">
					<h1 className='banner-title'>Contact Us</h1>
					<p className='sub-title-banner'>Empowering businesses with tailored solutions to drive growth</p>
				</div>
			</div>
			<div className="contact-paged">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-6 col-md-6">
							<div className="contact-address-box" data-aos="fade-right">
								<div className="mail-box">
									<div className="icon-img-contact">
										<img src={location} className='contactimg' alt="" />
									</div>
									<div className="dettls">
										<h4 className='text-locate'>TECHNO FACADE INTERIOR AND EXTERIOR BIDIYATH AL MAJAD GLOBAL PROJECT CR 1372024 BARKA,SULTANATE OF OMAN</h4>
									</div>
								</div>
								<div className="mail-box">
									<div className="icon-img-contact">
										<img src={mail} className='contactimg' alt="" />
									</div>
									<div className="dettls">
									<h4 className='text-locate'>
  <a href="mailto:info@technofacade.com">info@technofacade.com</a>
</h4>
									</div>
								</div>
								<div className="mail-box">
									<div className="icon-img-contact">
										<img src={call} className='contactimg' alt="" />
									</div>
									<div className="dettls">
										<h4 className='text-locate'>
											<a href="tel:+96878909514">+96878909514</a>
										</h4>									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="contact-box-form" data-aos="fade-left">
								<div className="main-box-contact">
									<div className="contact-main">
										<form action="https://api.web3forms.com/submit" method="POST">
											<input type="hidden" name="access_key" value="1b84e6f9-6323-42b8-beaf-0b60fc1364c2" />
											<div className="form-group">
												<label for="exampleInputEmail1">Name</label>
												<input type="text" name="name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name" />
											</div>
											<div className="form-group">
												<label for="exampleInputEmail1">Email address</label>
												<input type="email" name="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
											</div>
											<div className="form-group">
												<label for="exampleInputEmail1">Phone Number</label>
												<input type="number" name="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Number" />
											</div>
											<div className="form-group">
												<label for="exampleFormControlTextarea1">Message</label>
												<textarea className="form-control" name="Message" id="exampleFormControlTextarea1" rows="4"></textarea>
											</div>
											<input type="checkbox" name="botcheck" className="hidden" style={{ display: 'none' }} />
											<button type='submit' className='btn submit-btn'>Submit Now</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	)
}


export default Contact;