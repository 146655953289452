import React, { useRef, useEffect } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import banner from '../Assets/banner.svg'
import about from '../Assets/Latest/int.png'
import love from '../Assets/new/love.png'
import leave from '../Assets/new/leaf.png'
import qul from '../Assets/new/quality.png'
import ai from '../Assets/new/ai.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';
import val from '../Assets/Latest/image 534.svg'
import offer2 from '../Assets/Latest/shutter.svg'
import offer3 from '../Assets/Latest/products/kitchen2.png'
import offer33 from '../Assets/Latest/products/crtwall.png'
import offer4 from '../Assets/Latest/products/tmbglass.png'
import offer5 from '../Assets/Latest/products/upvcdoor.png'
import offer6 from '../Assets/Latest/products/almnwindow.png'
import offer from '../Assets/Latest/wp.png'
import pdf from '../Assets/TFBrosure.pdf'


const Home = () => {

	var settingsBanner = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnHover: false,
		arrows: false,
		responsive: [

			{
				breakpoint: 768, // At screen width 768px and below
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480, // At screen width 480px and below
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnHover: false,
		arrows: false,
		responsive: [

			{
				breakpoint: 768, // At screen width 768px and below
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480, // At screen width 480px and below
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	};
	var settingsClients = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnHover: false,
		arrows: false,
		responsive: [

			{
				breakpoint: 768, // At screen width 768px and below
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480, // At screen width 480px and below
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};




	useEffect(() => {
		AOS.init({
			duration: 2000, // Animation duration in milliseconds
			once: true, // Trigger animation only once
		});

		window.addEventListener('scroll', AOS.refresh); // Refresh AOS on scroll

		return () => {
			window.removeEventListener('scroll', AOS.refresh); // Cleanup on component unmount
		};
	}, []);


	return (
		<div className="home-page">
			<Slider  {...settingsBanner} className=''>
				<div className='bg-mob'>
					<div className="item-content" data-aos="zoom-in">
						<h3 className='heroo'>Experience</h3>
						<h3 className='heroo'>Excellence Every Day</h3>
						<h2 className='sub-hero'>Step into the world of modern design and functionality with Techno Interiors, the most trusted brand in uPVC Windows and Doors.</h2>
						<Link to='/' className='btn-home-explore'>About Us</Link>
						<a href={pdf} className="btn-home-download" download>
							Download Brochure
						</a>					</div>
				</div>
				<div className='bg-mob'>
					<div className="item-content" data-aos="zoom-in">
						<h3 className='heroo'>Experience</h3>
						<h3 className='heroo'>Excellence Every Day</h3>
						<h2 className='sub-hero'>Step into the world of modern design and functionality with Techno Interiors, the most trusted brand in uPVC Windows and Doors.</h2>
						<Link to='/' className='btn-home-explore'>About Us</Link>
						<a href={pdf} className="btn-home-download" download>
							Download Brochure
						</a>										</div>
				</div>
				<div className='bg-mob'>
					<div className="item-content" data-aos="zoom-in">
						<h3 className='heroo'>Experience</h3>
						<h3 className='heroo'>Excellence Every Day</h3>
						<h2 className='sub-hero'>Step into the world of modern design and functionality with Techno Interiors, the most trusted brand in uPVC Windows and Doors.</h2>
						<Link to='/' className='btn-home-explore'>About Us</Link>
						<a href={pdf} className="btn-home-download" download>
							Download Brochure
						</a>										</div>
				</div>
				<div className='bg-mob'>
					<div className="item-content" data-aos="zoom-in">
						<h3 className='heroo'>Experience</h3>
						<h3 className='heroo'>Excellence Every Day</h3>
						<h2 className='sub-hero'>Step into the world of modern design and functionality with Techno Interiors, the most trusted brand in uPVC Windows and Doors.</h2>
						<Link to='/' className='btn-home-explore'>About Us</Link>
						<a href={pdf} className="btn-home-download" download>
							Download Brochure
						</a>										</div>
				</div>
				<div className='bg-mob'>
					<div className="item-content" data-aos="zoom-in">
						<h3 className='heroo'>Experience</h3>
						<h3 className='heroo'>Excellence Every Day</h3>
						<h2 className='sub-hero'>Step into the world of modern design and functionality with Techno Interiors, the most trusted brand in uPVC Windows and Doors.</h2>
						<Link to='/' className='btn-home-explore'>About Us</Link>
						<a href={pdf} className="btn-home-download" download>
							Download Brochure
						</a>										</div>
				</div>
			</Slider>
			<div className="marquee">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-12">
							<div className="moving-text">
								<marquee width="100%" direction="left" height="100%" behavior="scroll" scrollamount="3">
									<p ><span className='text-moving'>WPC DOORS</span><span className='text-moving'>SHUTTERS & GATES</span><span className='text-moving'>KITCHEN INTERIOR	</span><span className='text-moving'>	TEMPERRED GLASS					</span><span className='text-moving'>TEMPERRED GLASS</span></p>
								</marquee>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="what-we-offer">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-12">
							<h1 className='wh'>Our Services</h1>
							<div className="offer-main">
								<Slider  {...settingsClients}>
									<div className="offer-slider">
										<img src={offer5} className='offer-img-sldr' alt="" />
										<div className="items-boxx">
											<h3 className='item-namee'>		 UPVC DOORS WINDOWS								</h3>
											<p className='item-subb'>a hard plastic material commonly
												used in windows and doors</p>
											<Link to='/contact' className='btn-home-shop'>Shop Now</Link>
										</div>
									</div>
									<div className="offer-slider">
										<img src={offer6} className='offer-img-sldr' alt="" />
										<div className="items-boxx">
											<h3 className='item-namee'>ALUMINIUM & WINDOWS								</h3>
											<p className='item-subb'>Aluminium windows have a very slim profile
												that defines the light system,</p>
											<Link to='/contact' className='btn-home-shop'>Shop Now</Link>
										</div>
									</div>
									<div className="offer-slider">
										<img src={offer} className='offer-img-sldr' alt="" />
										<div className="items-boxx">
											<h3 className='item-namee'> 	WPC DOORS	</h3>
											<p className='item-subb'>is a hard plastic material commonly
												used in windows and doors. </p>
											<Link to='/contact' className='btn-home-shop'>Shop Now</Link>
										</div>
									</div>
									<div className="offer-slider">
										<img src={offer4} className='offer-img-sldr' alt="" />
										<div className="items-boxx">
											<h3 className='item-namee'>TEMPERED GLASS									</h3>
											<p className='item-subb'>Tempered glass is known for its strength and
												safety</p>
											<Link to='/contact' className='btn-home-shop'>Shop Now</Link>
										</div>
									</div>
									<div className="offer-slider">
										<img src={offer3} className='offer-img-sldr' alt="" />
										<div className="items-boxx">
											<h3 className='item-namee'>KITCHEN INTERIOR											</h3>
											<p className='item-subb'>The kitchen, being one of the areas in the
												house that has heavy footfall, needs to
												remain very well organized</p>
											<Link to='/contact' className='btn-home-shop'>Shop Now</Link>
										</div>
									</div>
									<div className="offer-slider">
										<img src={offer33} className='offer-img-sldr' alt="" />
										<div className="items-boxx">
											<h3 className='item-namee'>ALUMINIUM  CURTAIN WALLS			</h3>
											<p className='item-subb'>A curtain wall is any exterior wall that is attached
												to the building structure</p>
											<Link to='/contact' className='btn-home-shop'>Shop Now</Link>
										</div>
									</div>
									<div className="offer-slider">
										<img src={offer2} className='offer-img-sldr' alt="" />
										<div className="items-boxx">
											<h3 className='item-namee'>SHUTTERS & GATES											</h3>
											<p className='item-subb'>Shutters and gates play an integral role in securing
												homes and places</p>
											<Link to='/contact' className='btn-home-shop'>Shop Now</Link>
										</div>
									</div>
								</Slider>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="aboutus-section">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-6 col-md-6">
							<div className="about-inner-box btmm" data-aos="fade-right">
								<img src={about} className='about-image' alt="" />
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="about-inner-box" data-aos="fade-left">
								<h4 className='title-about-us'>About Us</h4>
								<h2 className='title-about-us-main'>Who We Are</h2>
								<p className='sub-t-about-us'>Step into the world of modern design and functionality with Techno Interiors,
									the most trusted brand in uPVC Windows and Doors. As an Oman-based
									manufacturer, we pride ourselves on delivering superior quality products that
									enhance the aesthetics and performance of any space. Our mission is to
									provide innovative solutions that elevate our clients' lifestyles. Our vision is to
									be the industry leader in sustainable and energy-efficient windows and doors.
									With our EnCraft uPVC windows and doors, you can enjoy the perfect blend
									of style, safety, and security. Choose Techno Interiors for the best uPVC
									windows and doors that transform any space into a haven of comfort.
								</p>
								<div className="border-dv"></div>
								<Link to='/' className='btn-home-explore'>More about us</Link>
								<Link to='/' className='btn-home-download-a'>Download Brochure</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="why-shop">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-12">
							<div className="shop-main">
								<h1 className='shop-with-txt'>Why Shop With Us?</h1>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="bg-shop" data-aos="zoom-in-down">
								<div className="shop-half">
									<img src={qul} className='shop-img' alt="" />
									<h4 className='shop-title'>Unmatched Quality</h4>
									<p className='shop-sub-title'>At Techno Interiors, quality is the foundation of every product we create, ensuring that each window and door is crafted with precision and durability to withstand the test of time.</p>
								</div>
								<div className="shop-half">
									<img src={leave} className='shop-img' alt="" />
									<h4 className='shop-title'>Diverse Product Range</h4>
									<p className='shop-sub-title'>Our diverse product range at Techno Interiors caters to every need, offering an extensive selection of uPVC windows and doors designed to suit various styles and preferences.</p>
								</div>
								<div className="shop-half">
									<img src={love} className='shop-img' alt="" />
									<h4 className='shop-title'>Customer-Centric Approach</h4>
									<p className='shop-sub-title'>At Techno Interiors, our customer-centric approach drives everything we do, ensuring personalized solutions that truly reflect each client's unique vision and requirements.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="values">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-6 col-md-6">
							<div className="values-sub" data-aos="fade-up" data-aos-duration="1500">
								<h1 className='our-values'>Our Values</h1>
								<h3 className='our-values-title'>Sustainability</h3>
								<p className='our-values-sub'>Techno Interiors is committed to sustainability, incorporating eco-friendly practices in every step of our manufacturing process.</p>
								<h3 className='our-values-title'>Innovation</h3>
								<p className='our-values-sub'>Innovation is at the heart of Techno Interiors, where we continually explore advanced technologies to enhance our uPVC windows and doors.
								</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="values-sub-img" data-aos="fade-up" data-aos-duration="1500">
								<img src={val} className='value-img' alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="exclusive exctop">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-12">
							<div className="exclusive-box">
								<div className="exclusv-half">
									<h2 className='exclusive-text'>Exclusive</h2>
									<p className='exclusive-sub-text'>Get exclusive access to special deals, early product launches, and rewards every time you
										shop with us. Join our loyalty program today and start saving on your favorite products!</p>
								</div>
								<div className="exclusv-icon">
									<img src={ai} className='img-ai' alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

















			


		</div>
	)
}

export default Home