import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import tech from '../Assets/new/project-management.png'
import ai from '../Assets/new/ai.svg'
import it from '../Assets/new/settings.png'
import general from '../Assets/new/business-and-trade.png'
import fac from '../Assets/new/facility.png'

const Services = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Trigger animation only once
    });

    window.addEventListener('scroll', AOS.refresh); // Refresh AOS on scroll

    return () => {
      window.removeEventListener('scroll', AOS.refresh); // Cleanup on component unmount
    };
  }, []);


  return (
    <div className='about-page'>
      <div className="common-banner e">
        <div className="container-main">
          <h1 className='banner-title'>Our Services</h1>
          <p className='sub-title-banner'>Empowering businesses with tailored solutions to drive growth</p>
        </div>
      </div>
      <div className="aboutus-section">
        <div className="container-main">
          <div className="row">
            <div className="col-lg-12">
              <div className="services-inner" data-aos="zoom-in-down">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="srvc">
                      <img src={general} className='service-icon' alt="" />
                      <h4 className='servc-name'>Interior Services</h4>
                      <p className='servc-sub-name'>Our Interior Services provide personalized, high-quality solutions to transform your indoor spaces into stylish, functional, and comfortable environments. From expert painting, flooring installation, and lighting design to custom cabinetry, remodeling, and detailed cleaning, our team brings creativity and precision to every project. </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="srvc">
                      <img src={tech} className='service-icon' alt="" />
                      <h4 className='servc-name'>Exterior Services</h4>
                      <p className='servc-sub-name'>Our comprehensive Exterior Services are designed to enhance and maintain the appearance, functionality, and value of your property. From meticulous landscaping and lawn care to pressure washing, painting, and seasonal clean-ups, we offer tailored solutions that fit your needs. Our skilled team uses high-quality materials</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>











      <div className="exclusive">
        <div className="container-main">
          <div className="row">
            <div className="col-lg-12">
              <div className="exclusive-box">
                <div className="exclusv-half">
                  <h2 className='exclusive-text'>Exclusive</h2>
                  <p className='exclusive-sub-text'>Get exclusive access to special deals, early product launches, and rewards every time you
                    shop with us. Join our loyalty program today and start saving on your favorite products!</p>
                </div>
                <div className="exclusv-icon">
                  <img src={ai} className='img-ai' alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services;